<template>
  <div style="background: #f6f6f6;color: #000;">
    <van-nav-bar title="帐单列表" left-arrow :fixed="true" :z-index="999" @click-left="back">
    </van-nav-bar>
    <div class="nav-bar">
      <van-row class="topss">
        <van-col span="8" @click="setacv(0)" :class="acv == 0 ? 'red' : ''">待结</van-col>
        <van-col span="8" @click="setacv(1)" :class="acv == 1 ? 'red' : ''">已结</van-col>
        <van-col span="8" @click="setacv(-1)" :class="acv == -1 ? 'red' : ''">已撤销</van-col>
      </van-row>
      <van-row class="mian_row" style="font-weight: bold;">
        <van-col span="7" style="padding-right: 5px;">编号</van-col>
        <van-col span="7">业务来源</van-col>
        <van-col span="4" v-if="acv == 1">结款人</van-col>
        <van-col span="6" v-if="acv == 1" style="text-align: right;padding-right: 20px;">
          结款时间
        </van-col>
        <van-col span="4" v-if="acv != 1">业务员</van-col>
        <van-col span="6" v-if="acv != 1" style="text-align: right;padding-right: 20px;">
          状态
        </van-col>
      </van-row>
    </div>
    <div class="pt">
      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads"
        :offset="10">
        <div class="mian" v-for="(item, idx) in list" :key="idx" @click="tourls(item)">
          <van-row class="mian_row">
            <van-col span="7" style="padding-right: 5px;">{{ item.accountNumber.substring(7, 16) }}</van-col>
            <van-col span="7">{{ item.source }}</van-col>
            <van-col span="4">{{ acv == 1?item.name:item.sellsMan }}</van-col>
            <van-col span="5" style="text-align: right;padding-right: 2px;">
              <span v-if="item.status == 0" style="color: red;">待结</span>
              <span v-if="item.status == 1" style="font-size: 12px;">{{ item.settleDate.substring(5, 16) }}</span>
              <span v-if="item.status == -1" style="color: rgb(40, 36, 255);">已撤销</span>
            </van-col>
            <van-col span="1"><van-icon name="arrow" /></van-col>
          </van-row>

        </div>
      </van-list>
    </div>

    <van-empty v-if="list.length < 1" description="暂无数据~" />
  </div>
</template>
  
<script>
import { accountlist } from "@/api/check";
export default {
  data() {
    return {
      list: [], //
      gid: this.$route.query.gid,
      source: this.$route.query.source ? this.$route.query.source : '',
      key: "",
      page: 0,
      acv: 0,
      size: 20,
      loading: false,
      total: 0,
      finished: false,
      isiOS: false,
    };
  },
  methods: {
    tourls(item) {
      this.$router.push({
        path: "/bill_details",
        query: {
          gid: this.gid,
          source: item.source,
          accountNumber: item.accountNumber,
        },
      });
    },
    setacv(type) {
      this.acv = type;
      this.list = []
      this.page = 0
      this.finished = false;
      this.getlist();
    },
    onLoads() {
      this.page++;
      this.getlist();
    },
    getlist() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      var data = {
        gid: this.gid,
        page: this.page,
        size: this.size,
        status: this.acv,
        source: this.source
      };
      accountlist(data).then((e) => {
        loading.clear();
        if (e.code == 200) {
          let rows = e.data.content;

          this.loading = false;
          this.total = e.data.totalElements;

          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        }
      });
    },

    back() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getlist();
    //判断是否在微信环境

  },
  mounted() { },
  computed: {},
};
</script>
<style>
body {
  background: #f6f6f6;
}
</style>
<style lang="less" scoped>
.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.topss {
  line-height: 35px;
  font-size: 17px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  color: #666;
}

.red {
  color: red;
  font-weight: bold;
}

.hedt {
  line-height: 35px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  // padding-top: 46px;
}

.flx {
  display: flex;
  align-items: center;
}

.pt {
  padding-top: 46px;
}

.mian {
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  padding: 5px 0;
  background: #fff;
}

.mian_row {
  line-height: 35px;
  font-size: 14px;

  padding: 0 10px;
  background: #fff;
}

.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;

  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}
</style>

  
   